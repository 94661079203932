/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import {
  LooseRichTextSchema,
  type LoosenRichTextFields,
} from '../types/loose-rich-text'
import type { Equal, ExpectTrue } from '../types/validation'

import { type Device, DeviceSchema } from './device'
import { type Image, ImageSchema } from './image'
import {
  type RelatedLinkProps,
  RelatedLinkPropsSchema,
} from './related-links-content'
import type { RichText } from './rich-text'

export const BannerTextColor = {
  White: 'white',
  Black: 'black',
} as const

export type BannerTextColor =
  (typeof BannerTextColor)[keyof typeof BannerTextColor]

export type Banner = {
  textColor: BannerTextColor
  image: Image
  hiddenDevices: Device[]
  relatedLinks?: RelatedLinkProps
  subtitle?: RichText
}

/* Schemas */
export const BannerTextColorSchema = z.enum([
  BannerTextColor.White,
  BannerTextColor.Black,
])

export const BannerSchema = z.object({
  textColor: BannerTextColorSchema,
  image: ImageSchema,
  hiddenDevices: z.array(DeviceSchema),
  relatedLinks: RelatedLinkPropsSchema.optional(),
  subtitle: LooseRichTextSchema.optional(),
})

/* Schemas validation */
type TestBanner = ExpectTrue<
  Equal<LoosenRichTextFields<Banner>, z.infer<typeof BannerSchema>>
>
